<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="24" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>编辑视频稿件</span>
            <el-button style="float: right; padding: 10px" type="text" @click="onReturnVideo">返回视频稿件列表</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>更新视频封面</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateVideoCover">更新</el-button>
            </div>
            <div class="text item">
              <el-tooltip class="item" effect="dark" content="点击上传图片" placement="top-end">
                <el-upload
                  class="avatar-uploader"
                  :action="imgOssUrl"
                  :headers="imgHeaders"
                  :data="imgData"
                  :with-credentials="true"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :on-change="handleOnChange"
                >
                  <img :src="coverUrl" class="avatar">
                </el-upload>
              </el-tooltip>
            </div>
          </el-card>
        </el-row>
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>更新视频文件</span>
              <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateVideoFile">更新</el-button>
            </div>
            <div class="text item">
              <uploader
                class="uploader-example"
                :options="options"
                :auto-start="true"
                @file-added="onFileAdded"
                @file-success="onFileSuccess"
                @file-progress="onFileProgress"
                @file-error="onFileError"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p>拖动视频文件到此处或</p>
                  <uploader-btn :attrs="attrs">选择视频文件</uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </el-card>
        </el-row>
      </el-col>
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>更新视频信息</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateVideoInfo">更新</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="videoInfoForm" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="videoInfoForm.title" style="padding-right: 1px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="videoInfoForm.description" type="textarea" autosize style="padding-right: 1px;" />
              </el-form-item>
              <el-form-item label="发布时间">
                <el-date-picker
                  v-model="videoInfoForm.pubDate"
                  type="datetime"
                  placeholder="选择发布的时间"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { getVideoPost, updateVideoInfo, updateVideoCover, updateVideoFile } from '@/api/video'
import { getVideoChannelInfo, getVideoCoverChannelInfo } from '@/api/file'

export default {
  name: 'VideoPostEdit',
  data() {
    return {
      // ****************************************************************************************************************
      options: null,
      attrs: {
        accept: 'video/*'
      },
      imgOssUrl: null,
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelId: process.env.VUE_APP_UPLOAD_COVER_CHANNEL
      },
      // ****************************************************************************************************************
      coverUrl: null,
      coverUrl1: null,
      coverFileId: null,
      videoFileId: null,
      // 提交给后端的数据
      videoInfoForm: {
        videoId: null,
        title: null,
        description: null,
        pubDate: null
      },
      ossToken: null
    }
  },
  created() {
    document.title = '编辑视频稿件'

    const videoId = this.$route.params.videoId
    getVideoPost(videoId).then(res => {
      if (res.code === 0) {
        const userVideoPost = res.data
        this.coverUrl = userVideoPost.coverUrl
        this.videoInfoForm.videoId = userVideoPost.videoId
        this.videoInfoForm.title = userVideoPost.title
        this.videoInfoForm.description = userVideoPost.description
        this.videoInfoForm.pubDate = userVideoPost.pubDate
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })

    getVideoChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.options = {
          target: resData.ossUrl,
          chunkSize: resData.maxSize,
          fileParameterName: 'file',
          testChunks: false,
          query: (file, chunk) => {
            return {
              channelId: resData.channelCode
            }
          },
          headers: {
            Authorization: 'Bearer ' + resData.token
          },
          withCredentials: false
        }
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: '视频上传配置失败 ' + error.message,
        type: 'warning',
        duration: 3000
      })
    })

    getVideoCoverChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.imgOssUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: '图片上传配置失败 ' + error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  methods: {
    // ****************************************************************************************************************
    onFileAdded(file) {
      if (file.file.size > 1024 * 1024 * 1024 * 10) {
        file.cancel()
        this.$notify(
          {
            title: '提示',
            message: '视频文件应小于 10GB',
            type: 'warning',
            duration: 3000
          }
        )
        return
      }
    },
    onFileProgress(rootFile, file, chunk) {
    },
    onFileSuccess(rootFile, file, response, chunk) {
      const res = JSON.parse(response)
      if (res.code === 0) {
        const resData = res.data
        this.videoFileId = resData.uploadId

        this.$notify(
          {
            title: '提示',
            message: '视频已上传',
            type: 'warning',
            duration: 3000
          }
        )
      }
    },
    onFileError(rootFile, file, response, chunk) {
      this.$notify({
        title: '提示',
        message: '文件上传错误',
        type: 'warning',
        duration: 3000
      })
    },
    // ****************************************************************************************************************
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res, file) {
      const localImageUrl = URL.createObjectURL(file.raw)
      if (res.code === 0) {
        const resData = res.data
        this.coverFileId = resData.uploadId
        this.coverUrl = localImageUrl
        this.coverUrl1 = resData.url
      } else {
        this.$notify({
          title: '提示',
          message: '视频封面上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    handleOnChange(file, fileList) {
    },
    // ****************************************************************************************************************
    onReturnVideo() {
      this.$router.push('/post/list/video')
    },
    onUpdateVideoInfo() {
      updateVideoInfo(this.videoInfoForm).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '视频信息已更新',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    onUpdateVideoCover() {
      if (this.coverUrl1 === null) {
        this.$notify({
          title: '提示',
          message: '你还没有上传视频封面',
          type: 'warning',
          duration: 3000
        })
        return
      }

      const videoCover = {
        videoId: this.videoInfoForm.videoId,
        coverUrl: this.coverUrl1,
        coverFileId: this.coverFileId
      }

      updateVideoCover(videoCover).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '视频封面已更新',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    onUpdateVideoFile() {
      if (this.videoFileId === null) {
        this.$notify({
          title: '提示',
          message: '你还没有上传视频文件',
          type: 'warning',
          duration: 3000
        })
        return
      }

      const videoFile = {
        videoId: this.videoInfoForm.videoId,
        videoFileId: this.videoFileId
      }

      updateVideoFile(videoFile).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '视频文件已更新',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .coverImg {
    height: 120px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.uploader-example {
  width: 500px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 240px;
  display: block;
}
</style>
